import React from 'react';
import './notFound.css'
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";

const NotFound = () => {
    const location = useLocation();
    const canonicalUrl = `https://vintrust.pro${location.pathname}`;
    return (
        <div className='notfound-container'>
            <Helmet>
                <link rel="canonical" href={canonicalUrl}/>
                <meta name="robots" content="noindex, nofollow"/>
            </Helmet>
            <div className='notfound-small-container'>
                <h1 className='notfound-big-text'>Oops!</h1>

                <div className="horizontal-line"></div>

                <div className='notfound-medium-text-container'>
                    <p>404.</p>
                    <h2>Page Not Found</h2>
                </div>

                <p className='notfound-small-text'>The page you're looking for cannot be
                    found.</p>

                <div className='notfound-visit-home-page-container'>
                    <p className='notfound-small-text'>Please </p>
                    <a href="/">visit our homepage</a>
                </div>
            </div>
        </div>
    );
};

export default NotFound;